import * as React from "react"
import { Section, Avatar, KeyValue, Image } from "@bw/bits"
import { HighlightTeaser, NewsTeaser } from "@bw/partials"
import { Layout } from "@bw/layouts"
import styled from "styled-components"
import { graphql } from "gatsby"
import { mediaQuery } from "../theme"

const BlogListing = ({ data, pageContext }) => {
  const { locale } = pageContext

  const allArticles = data.allCollectionArticles.nodes.map(article => ({
    ...article,
    date: new Date(article.date).toLocaleDateString(locale, {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    }),
  }))
  const highlight = allArticles[0]
  const articles = allArticles.slice(1)

  return (
    <Layout {...{ pageContext }}>
      <Section>
        <Grid>
          {highlight && (
            <HighlightWrapper>
              <HighlightTeaser
                image={highlight?.teaserImage?.localImage || null}
                date={highlight.date}
                title={highlight.title}
                authors={highlight.authors.map(a => a.name).join(", ")}
                excerpt={highlight.teaserExcerpt}
                to={highlight.slug}
              />
            </HighlightWrapper>
          )}
          {articles.map(
            ({
              id,
              title,
              slug,
              date,
              teaserImage,
              teaserExcerpt,
              authors,
            }) => (
              <NewsTeaser
                key={id}
                subtitle={
                  <KeyValue
                    indicator="par"
                    variable={authors.map(a => a.name).join(", ")}
                    direction="row"
                  />
                }
                title={title}
                excerpt={teaserExcerpt}
                to={slug}
                image={
                  <Image
                    type="BlogListing"
                    image={teaserImage?.localImage}
                    tag={date}
                    avatar={<Avatar />}
                  />
                }
              />
            )
          )}
        </Grid>
      </Section>
    </Layout>
  )
}

export default BlogListing

export const newsQuery = graphql`
  query newsQuery($locale: String) {
    allCollectionArticles(
      filter: { locale: { eq: $locale } }
      sort: { fields: statamicId, order: DESC }
    ) {
      nodes {
        id
        teaserImage {
          localImage {
            childImageSharp {
              gatsbyImageData(width: 800, height: 600)
            }
          }
        }
        date
        authors {
          name
        }
        teaserExcerpt
        title
        locale
        slug
      }
    }
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 45px;

  ${mediaQuery("small")`
    grid-template-columns: 1fr 1fr;
  `};

  ${mediaQuery("medium")`
    grid-template-columns: 1fr 1fr 1fr;
  `};

  ${mediaQuery("large")`
    grid-template-columns: 1fr 1fr 1fr 1fr;
  `};
`

const HighlightWrapper = styled.div`
  ${mediaQuery("small")`
  grid-column: 1 / 3;
  `}
`
